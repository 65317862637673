<template>
    <div
        class="d-flex h-100 bgi-no-repeat bgi-size-cover bgi-attachment-fixed auth-bg flex-column"
    >
<!--        <SelectLanguage class="auth-select-language" />-->
        <!--begin::Content-->
        <div
            class="auth-content w-100"
            style="
                background-image: url('/media/logos/buying-logo-auth-bg.png');
                background-repeat: no-repeat;
                background-position: center"
        >
            <div class="auth-content_container">
                <div class="auth-content_container__header">
                    <img :src="platformConfigs.platformLogos.whiteLogo" alt="">
                </div>
                <router-view></router-view>
            </div>
        </div>

        <div class="auth-content_container__footer">
            <div class="footer-title">{{ $t("To learn More") }}</div>
            <div class="footer-link">
                <a href="#">{{ $t("Visit Our Webpage") }}</a>
            </div>
        </div>

        <!--end::Content-->
    </div>
</template>

<script>
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import store from "@/store";
import SelectLanguage from "@/buying-teams/shared-components/utils/SelectLanguage";

export default {
    name: "BankRegister",
    components: {
        SelectLanguage
    },
    async mounted() {
        if (!store.getters.isUserAuthenticated) {
            redirectToRouteWithName("sign-in");
        }
    },
    computed: {
        platformConfigs() {
            return store.getters.platformConfigs;
        }
    }
};
</script>

<style lang="scss">
.bank-register {
    position: relative;

    &__step {
        position: absolute;
        top: -12px;
        right: 2px;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        display: flex;
        align-items: center;

        a {
            display: flex;
            margin-right: 16px;
        }
    }

    &__form {
        margin-top: 30px;

        .form-label {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #434343;
            opacity: 0.6;
            margin-bottom: 0;
        }

        .el-input__inner {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #E6E6E6;
            padding-left: 0;
            padding-right: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }

        .el-select {
            width: 100%;

            .el-input__inner {
                width: 100%;
                background: transparent;

                &::placeholder {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(67, 67, 67, 0.6);
                }
            }
        }

        .hide-placeholder {
            .el-form-item__content {
                position: relative;

                label {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #434343;
                    opacity: 0.6;
                }
            }

            .el-select {
                .el-input__inner {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
    }
}
.auth-select-language {
    position: absolute;
    right: 80px;
    top: 50px;
}

@media (max-width: 1100px) {
    .auth-content_container {
        margin-top: 100px;
    }
}

@media (max-width: 600px) {
    .auth-select-language {
        right: 50%;
        transform: translateX(50%);
    }
}
</style>
